@import "../../../mixins";

@mixin mediaCTAMobile {
  @media screen and (max-width: #{rem(740)}) {
    @content;
  }
}

.cta-section.cta-section_t3 {
  & .cta-section {
    &__content {
      gap: rem(50);

      @include mediaBigDesktop {
        gap: big(50);
      }

      @include mediaDesktop {
        gap: rem(36);
      }

      @include mediaLaptop {
        gap: rem(30);
      }

      @include mediaTablet {
        max-width: unset;
        flex-basis: unset;
        gap: rem(25);
      }

      @include mediaCTAMobile {
        gap: rem(20);
      }
    }

    &__head {
      gap: rem(20);

      @include mediaBigDesktop {
        gap: big(20);
      }

      @include mediaLaptop {
        gap: rem(15);
      }

      @include mediaMobile {
        gap: rem(10);
      }
    }

    &__description {
      color: var(--text-dark-primary);
      font: 600 rem(32)/1.4 var(--font-primary);

      @include mediaBigDesktop {
        font-size: big(32);
      }

      @include mediaDesktop {
        font-size: rem(24);
      }

      @include mediaLaptop {
        font-size: rem(18);
      }

      @include mediaTablet {
        font-size: rem(16);
      }

      @include mediaCTAMobile {
        font-size: rem(14);
      }
    }
  }
}
